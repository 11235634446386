const util = function () {
    let data = {
        getWeChatUrl: function (a) {
            return "https://open.weixin.qq.com/connect/qrconnect?" +
                "appid=" + a.appid +
                "&scope=" + a.scope +
                "&redirect_uri=" + a.redirect_uri +
                "&state=" + a.state +
                "&login_type=jssdk" +
                "&self_redirect=" + (a.self_redirect.toString()) +
                '&styletype=' + (a.styletype || '') +
                '&sizetype=' + (a.sizetype || '') +
                '&bgcolor=' + (a.bgcolor || '') +
                '&rst=' + (a.rst || '') +
                '&style=' + (a.style || '') +
                '&href=' + (a.href || '');
        },
        isSuccessful: function (res) {
            if (res.status === 200 && res.data.code === '200') {
                return true;
            }
            return false;
        },
        uuid: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        isEmpty: function (str) {
            return !str || str.length === 0 || str.trim().length === 0;
        },
        isMobile: function (str) {
            return (/^1(0|1|3|4|5|6|7|8|9)\d{9}$/.test(str));
        },
        tipOnBtn: function (target, text, newText, time = 1000) {
            if (target.paus) {
                return;
            }
            target.className += ' alert';
            target.value = newText;
            target.paus = true;
            setTimeout(() => {
                target.value = text;
                target.className -= ' alert';
                target.paus = false;
            }, time);
        },
        param: function (name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            let href = location.href;
            if (href.indexOf('?') === -1) {
                return null;
            }
            let r = href.substr(href.indexOf('?') + 1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        }
    };
    return data;
}
export default new util();