<template>
    <div id="app">
        <div class="switch-lang">
            <span :class="$i18n.locale==='zh_CN'?'active':''" @click="switchLang('zh_CN')">中文</span>
            <!--<span :class="$i18n.locale==='en_US'?'active':''" @click="switchLang('en_US')">English</span>-->
        </div>
        <LoginBox v-if="showLoginBox" :wcKey="$util.param('key')"/>
        <!--<div class="copyright">&copy;2020 Skyense Inc</div>-->
    </div>
</template>
<script>
    import LoginBox from "./views/LoginBox";

    export default {
        components: {LoginBox},
        data() {
            return {
                showLoginBox: false,
            }
        },
        created() {
            let loginUser = this.$storage.get('user');
            if (loginUser) {
                if (loginUser.type === 3) {
                    location.href = "https://" + location.hostname + ":" + location.port + "/od/#/admin";
                } else if (loginUser.type === 2) {
                    location.href = "https://" + location.hostname + ":" + location.port + "/od/#/setup";
                } else if (loginUser.type === 1) {
                    location.href = "https://" + location.hostname + ":" + location.port + "/user";
                }
                return;
            }
            this.checkLogin();
        },
        methods: {
            switchLang(lang) {
                this.$i18n.locale = lang;
                this.$storage.set('lang', lang);
            },
            checkLogin() {
                let _this = this;
                let appKey = _this.$util.param('appKey'), user = _this.$util.param('user');
                if (appKey && user && appKey.length > 0 && user.length > 0) {
                    _this.login({appKey: appKey, user: user});
                    return;
                }
                let keydf = _this.$util.param('keydf');
                if (keydf && keydf.length > 0) {
                    _this.login({keydf: keydf});
                    return;
                }
                let key = _this.$util.param('key');
                if (key && key.length > 0) {
                    alert(_this.$t('lang.login.inputAlert.wechatLoginBind'))
                }
                let msg = _this.$util.param('msg');
                if (msg && msg.length > 0) {
                    alert(_this.$t('lang.login.inputAlert.wechatLoginFailed'))
                }
                this.showLoginBox = true;
            },
            async login(data) {
                let _this = this;
                _this.$http.post('/newLogin', data).then(res => {
                    if (_this.$util.isSuccessful(res)) {
                        let loginUser = res.data.data;
                        localStorage.setItem("loginUser", JSON.stringify(loginUser));
                        this.$storage.set('user', loginUser);
                        if (loginUser.type === 3) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/od/#/admin";
                        } else if (loginUser.type === 2) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/od/#/setup";
                        } else if (loginUser.type === 1) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/user";
                        }
                        return;
                    }
                    this.showLoginBox = true;
                }).catch(() => {
                    this.showLoginBox = true;
                });
            }
        }
    }
</script>

<style>
    #app {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .switch-lang {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 20px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        cursor: default;
    }

    .switch-lang span {
        margin-left: 10px;
    }

    .switch-lang span:hover,
    .switch-lang span.active {
        color: rgba(255, 51, 39,1);
    }
</style>
