<template>
    <transition name="fade">
        <div v-if="showDialog" :style="{marginTop:top}" class="dialog" @click="()=>{if(clickModel){close()}}">
            <div class="dialog-content" @click.stop="()=>{}">
                <div class="dialog-header" v-if="title">
                    <div class="dialog-title">{{ title }}</div>
                    <div class="dialog-close-btn" @click="close">×</div>
                </div>
                <div class="dialog-body">
                    <slot/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "ResetPwd",
        props: {
            title: {
                type: String,
                require: false
            },
            clickModel: {
                type: Boolean,
                require: false,
                default: true
            },
            top: {
                type: String,
                require: false,
                default: null
            },
        },
        data() {
            return {
                showDialog: false,
            }
        }, methods: {
            show() {
                this.showDialog = true;
            }, close() {
                this.showDialog = false;
            }
        }
    }
</script>

<style scoped>

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .dialog {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .dialog-content {
        background-color: #FFF;
        border-radius: 4px;
        min-width: 300px;
    }

    .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px 10px 16px;
        box-sizing: border-box;
    }

    .dialog-title {
        font-size: 16px;
        height: 30px;
        line-height: 30px;
    }

    .dialog-close-btn {
        font-size: 30px;
        line-height: 30px;
        width: 30px;
        height: 30px;
        text-align: center;
        cursor: default;
    }

    .dialog-close-btn:hover {
        color: #AAA;
    }

    .dialog-body {
        box-sizing: border-box;
    }
</style>