<template>
    <div class="login-box">
        <div class="login-box-title" v-html="$t('lang.login.title')"></div>
        <div v-if="loginType==='pwd'" class="login-box-body pwd">
            <div class="body-form">
                <div class="form-header" @click="switchLoginType('wechat')">
                    <span>{{$t('lang.login.pwdLogin')}}</span>
                    <img :alt="$t('lang.login.pwdLogin')"
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2BAMAAAB+a3fuAAAAKlBMVEUvlogvlogvlogvlogAAAAvlogvlogvlogvlogvlogvlogvlogvlogvlogxNCz1AAAADnRSTlMk1eu+AA6i//QygztQZ6fUsTMAAAGsSURBVHjajdI1n5QxEMfxENy6ewNo6M+7AQZ39w53n3O/tQqXDncqqpUWp8TlvTCx1eT53K/c79rzT8Sk+a4WlPMXIeLK+T4BimxNOJ0uaSOfQEm2FraxausSCKpiF+sMZ97lnrLtK75p7mS7rF8oGTO9Z0NcRb1s/citsDa5ufnrD2O7jfWsb25uA2ug6KC1ZdbWEp13hpIeadt5+Ch1a9tAdM4bKGO3iai33lAamx40OFhj/Htdzrgp1nIv7mlb8+LFa6h5PraFiP75sGLS2LixC/+43xUDxf+zYU+XbMFph09+eRsyaHJ7LiefQN8Ut+faZl/F0FmlidtqOhsxIe5Q38wrQkzFFULUGijqIt1lXEfnas1cOW/n6wyUtzUNn0OZSRN1ZVI79tO5HTtqbcWOLUQ9Ow5kMkRLRgTWxov14wYy1dvSZOuJGRSLN3HFXa6YFxiv0dwZtYXMn23IzDhdbpf6ZILBgn9PMjTy4kXAcC5PrQsZ4Hp3B0OtiRt8Z0mfDxrK+OfMM6bZ4h+MGKguc+eDSbNLOJgXN5QJBkJEDWVX3EDFDWWCwX+Q+s11XtzKzwAAAABJRU5ErkJggg=="/>
                </div>
                <div class="form-item">
                    <input ref="account" v-model="formData.phoneNumber" maxlength="11" :placeholder="$t('lang.login.placeholder.account')"/>
                </div>
                <div class="form-item">
                    <input ref="password" v-model="formData.password" maxlength="32" type="password" :placeholder="$t('lang.login.placeholder.password')"/>
                </div>
                <div class="form-item verifyCode">
                    <input ref="verifyCode" v-model="formData.verifyCode" :maxlength="verifyCode?verifyCode.length:6" :placeholder="$t('lang.login.placeholder.verifyCode')"/>
                    <input type="button" :value="verifyCode" @click="getVerifyCode"/>
                </div>
                <div class="form-item submitBtn">
                    <input ref="submitBtn" type="button" :value="$t('lang.login.submitBtn')" @click="login"/>
                </div>
                <div class="form-item function">
                    <span @click="$refs['reset-box'].show()">{{$t('lang.login.forgetPwd')}}</span>
                    <!--<span @click="$refs['create-box'].show()">{{$t('lang.login.createAccount')}}</span>-->
                </div>
            </div>
        </div>
        <div v-if="loginType==='wechat'" class="login-box-body wechat">
            <div class="body-form">
                <div class="form-header" @click="switchLoginType('pwd')">
                    <span>{{$t('lang.login.qrcodeLogin')}}</span>
                    <img :alt="$t('lang.login.qrcodeLogin')"
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2BAMAAAB+a3fuAAAAElBMVEUAAAAvlogvlogvlogvlogvlogNvzTNAAAABXRSTlMAKavn7+v2zkYAAACdSURBVHja5dNFjkJRFITh6gbmNylYAWGOLgCbP93/VnCtcw/u/4jkS/Eci/6aE60bllYvrYZLa5mWLW2aQmsUWFQm0GrlTTZwjI79tRc/D9oagmN4L6s49jeIG2iYDtVAw3SoBhqmQzXQMB2qgY79DdS0J5l+K/apZpB48G3aw1GAVpkDDjocIhYPTIZwhnCG8IZlmUSHcQOX9sPNAGKWgMkc7IQVAAAAAElFTkSuQmCC"/>
                </div>
                <div class="form-item">
                    <iframe ref="wechatIFrame" class="weChatIframe" frameborder="0" sandbox="allow-scripts allow-top-navigation allow-same-origin" allowtransparency="true" scrolling="no"></iframe>
                </div>
            </div>
        </div>
        <ResetBox ref="reset-box"/>
        <CreateBox ref="create-box"/>
    </div>
</template>

<style scoped>
    .login-box {
        margin-bottom: 50px;
        display: flex;
        flex-flow: column;
    }

    .login-box-title {
        text-align: center;
        font-size: 35px;
        color: rgba(255, 51, 39, 1);
        line-height: 50px;
        letter-spacing: 8px;
    }

    .login-box-body {
        border: 1px solid rgba(255, 51, 39, 1);
        border-radius: 4px;
        margin: 50px auto 0;
        background-color: #FFF;
        padding: 10px;
        font-size: 16px;
        color: #444;
        width: 300px;
    }

    .form-header {
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DDD;
        position: relative;
    }

    .form-header img {
        height: 30px;
        position: absolute;
        top: -30px;
        right: 0;
        filter: drop-shadow(0px 30px 0px rgba(66, 51, 39, 1));
    }

    .form-item {
        box-sizing: border-box;
        padding: 0 10px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    .form-item input {
        box-sizing: border-box;
        display: block;
        padding: 0 12px;
        height: 35px;
        line-height: 35px;
        flex: 1;
        border: 1px solid #DDD;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        background-color: #FFF;
        color: #666;
    }

    .verifyCode input:last-child {
        flex: 0.5;
        text-align: center;
        font-size: 20px;
        margin-left: 20px;
        cursor: default;
    }

    .submitBtn input {
        color: #FFF;
        background-color: rgba(255, 51, 39, 1);
        border: 0;
    }

    .function {
        font-size: 14px;
        cursor: default;
    }

    .function span:hover {
        color: rgba(255, 51, 39, 1);
    }

    .weChatIframe {
        min-height: 300px;
        margin: auto;
    }
</style>

<script>
    import ResetBox from "./ResetBox";
    import CreateBox from "./CreateBox";

    export default {
        name: 'Home',
        components: {CreateBox, ResetBox},
        props: {
            wcKey: {
                required: false,
                type: String
            }
        },
        data() {
            return {
                loginType: null,
                verifyCode: null,
                formData: {
                    phoneNumber: null,
                    password: null,
                    verifyCode: null,
                }
            }
        },
        created() {
            this.switchLoginType('pwd');
            let _this =this;
            window.onkeypress=function (e){
                let theEvent = window.event || e;
                let code = theEvent.keyCode || theEvent.which || theEvent.charCode;
                if (code == 13) {
                    _this.login();
                }
            }
        },
        methods: {
            switchLoginType(loginType) {
                let _this = this;
                _this.loginType = loginType;
                if (loginType === 'wechat') {
                    _this.$http.post('/wechat/login/getStateCode', {uk: _this.$storage.get('uk')}).then(res => {
                        if (!_this.$util.isSuccessful(res)) {
                            _this.loginType = 'pwdLogin';
                            return;
                        }
                        _this.$refs['wechatIFrame'].src = _this.$util.getWeChatUrl({
                            self_redirect: false,
                            id: "wechat-login-container",
                            appid: "wx9fd6c20813cfa215",
                            scope: "snsapi_login",
                            redirect_uri: "https://gkpw.hanwangzt.com/shungkon/wechat/login/callback",
                            state: res.data.data,
                            style: "black",
                            href: "https://tt.hanwangzt.com/wechat/wecaht-login-qrcode.css"
                        });
                    });
                }
                if (loginType === 'pwd') {
                    this.getVerifyCode();
                }
            },
            getVerifyCode() {
                let _this = this;
                _this.$http.post('/verifyCode', {uk: _this.$storage.get('uk')}).then(res => {
                    if (_this.$util.isSuccessful(res)) {
                        _this.verifyCode = res.data.data;
                    }
                });
            },
            login() {
                let _this = this;
                let refSubmitBtn = _this.$refs['submitBtn'];
                let langSubmitBtn = _this.$t('lang.login.submitBtn');
                if (_this.$util.isEmpty(_this.formData.phoneNumber)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.login.inputAlert.account'))
                    _this.$refs['account'].focus();
                    return;
                }
                if (!_this.$util.isMobile(_this.formData.phoneNumber)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.login.inputAlert.accountFormat'))
                    _this.$refs['account'].focus();
                    return;
                }
                if (_this.$util.isEmpty(_this.formData.password)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.login.inputAlert.password'))
                    _this.$refs['password'].focus();
                    return;
                }
                if (_this.$util.isEmpty(_this.formData.verifyCode)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.login.inputAlert.verifyCode'))
                    _this.$refs['verifyCode'].focus();
                    return;
                }
                _this.formData.uk = _this.$storage.get('uk');
                _this.formData.wcKey = _this.wcKey;
                _this.$http.post('/newLogin', _this.formData).then(res => {
                    if (_this.$util.isSuccessful(res)) {
                        let loginUser = res.data.data;
                        localStorage.setItem("loginUser", JSON.stringify(loginUser));
                        this.$storage.set('user', loginUser);
                        if (loginUser.type === 3) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/od/#/admin";
                        } else if (loginUser.type === 2) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/od/#/setup";
                        } else if (loginUser.type === 1) {
                            location.href = "https://" + location.hostname + ":" + location.port + "/user";
                        }
                    } else {
                        _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.login.inputAlert.failed'))
                    }
                });
            }
        }
    }
</script>
