<template>
    <Dialog ref="dialog" :title="$t('lang.reset.title')" top="-15vh" :click-model="false">
        <div class="form">
            <div class="form-item">
                <input ref="account" v-model="formData.phoneNumber" maxlength="11" :placeholder="$t('lang.reset.placeholder.account')"/>
            </div>
            <div class="form-item">
                <input type="password" v-model="formData.password" ref="password" maxlength="32" :placeholder="$t('lang.reset.placeholder.password')"/>
            </div>
            <div class="form-item verifyCode">
                <input ref="verifyCode" v-model="formData.verifyCode" maxlength="6" :placeholder="$t('lang.reset.placeholder.verifyCode')"/>
                <input ref="verifyCodeBtn" type="button" :value="$t('lang.reset.verifyCodeBtn')" @click="getVerifyCode"/>
            </div>
            <div class="form-item submitBtn">
                <input :class="!sended?'disabled':''" :disabled="!sended" ref="submitBtn" type="button" :value="$t('lang.reset.submitBtn')" @click="save"/>
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "../components/Dialog";

    export default {
        components: {
            Dialog
        },
        data() {
            return {
                formData: {
                    phoneNumber: null,
                    password: null,
                    verifyCode: null,
                },
                sended: false,
            }
        },
        methods: {
            show() {
                Object.assign(this.$data, this.$options.data());
                this.$refs['dialog'].show();
            },
            checkPhone() {
                let _this = this;
                let refSubmitBtn = _this.$refs['submitBtn'];
                let langSubmitBtn = _this.$t('lang.reset.submitBtn');
                if (_this.$util.isEmpty(_this.formData.phoneNumber)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.account'))
                    _this.$refs['account'].focus();
                    return false;
                }
                if (!_this.$util.isMobile(_this.formData.phoneNumber)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.accountFormat'))
                    _this.$refs['account'].focus();
                    return false;
                }
                return true;
            },
            getVerifyCode() {
                let _this = this;
                if (_this.sended || !_this.checkPhone()) {
                    return;
                }
                _this.$http.post('/sendCode', {
                    phoneNumber: _this.formData.phoneNumber
                }).then(res => {
                    if (_this.$util.isSuccessful(res)) {
                        _this.sended = true;
                        let ds = 180;
                        _this.$refs['verifyCodeBtn'].value = _this.$t('lang.reset.verifyCodeBtnSend').replace('$s', ds);
                        let interval = setInterval(() => {
                            if (ds > 1) {
                                ds = ds - 1;
                                _this.sended = true;
                                _this.$refs['verifyCodeBtn'].value = _this.$t('lang.reset.verifyCodeBtnSend').replace('$s', ds);
                            } else {
                                _this.$refs['verifyCodeBtn'].value = _this.$t('lang.reset.verifyCodeBtn');
                                clearInterval(interval)
                                _this.sended = false;
                            }
                        }, 1000);
                    } else {
                        _this.$util.tipOnBtn(_this.$refs['submitBtn'], _this.$t('lang.reset.submitBtn'), _this.$t('lang.reset.inputAlert.sendFailed'))
                    }
                });
            },
            save() {
                let _this = this;
                if (!_this.checkPhone()) {
                    return;
                }
                let refSubmitBtn = _this.$refs['submitBtn'];
                let langSubmitBtn = _this.$t('lang.reset.submitBtn');
                if (_this.$util.isEmpty(_this.formData.password)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.password'))
                    _this.$refs['password'].focus();
                    return;
                }
                if (_this.$util.isEmpty(_this.formData.verifyCode)) {
                    _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.verifyCode'))
                    _this.$refs['verifyCode'].focus();
                    return;
                }
                _this.$http.post('/resetPassword', {phoneNumber: this.formData.phoneNumber, code: this.formData.verifyCode, newPassword: this.formData.password}).then(res => {
                    if (_this.$util.isSuccessful(res)) {
                        _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.success'))
                        setTimeout(() => {
                            this.$refs['dialog'].close();
                        }, 1000);
                    } else {
                        _this.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _this.$t('lang.reset.inputAlert.failed'))
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .form {
        width: 380px;
        padding: 10px;
    }

    .form-item {
        box-sizing: border-box;
        padding: 0 10px;
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
    }

    .form-item input {
        box-sizing: border-box;
        display: block;
        padding: 0 12px;
        height: 42px;
        line-height: 42px;
        flex: 1;
        border: 1px solid #DDD;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        background-color: #FFF;
        color: #666;
    }

    .verifyCode input:last-child {
        flex: 0.7;
        text-align: center;
        margin-left: 20px;
        cursor: default;
        background-color: rgba(255, 51, 39, 1);
        border: 0;
        color: #FFF;
    }

    .verifyCode input:last-child.disabled {
        background-color: rgba(255, 51, 39, 1);
    }

    .submitBtn input {
        color: #FFF;
        background-color: rgba(255, 51, 39, 1);
        border: 0;
    }

    .submitBtn input.disabled {
        background-color: rgba(255, 51, 39, 0.8);
    }

</style>