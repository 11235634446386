export default {
    language: '中文',
    map: {
        lang: 'zh'
    },
    login: {
        title: '欢 迎 使 用<br/>高 空 抛 物 监 测 系 统',
        pwdLogin: '密码登录',
        qrcodeLogin: '扫码登录',
        submitBtn: '登录',
        forgetPwd: '忘记密码？',
        createAccount: '创建账号',
        placeholder: {
            account: '手机号',
            password: '登录密码',
            verifyCode: '验证码',
        },
        inputAlert: {
            account: '请输入手机号',
            accountFormat: '请输入正确的手机号',
            password: '请输入登录密码',
            verifyCode: '请输入验证码',
            success: '登录成功',
            failed: '登录失败，请检查用户名、密码以及验证码',
            wechatLoginFailed: '微信登录失败，请使用账号密码登录',
            wechatLoginBind: '您已使用微信登录，请输入账号密码进行登录绑定',
        }
    },
    reset: {
        title: '重置密码',
        verifyCodeBtn: '点击发送',
        verifyCodeBtnSend: '$s 秒后重试',
        submitBtn: '立即找回',
        placeholder: {
            account: '手机号',
            password: '登录密码',
            verifyCode: '验证码',
        },
        inputAlert: {
            account: '请输入手机号',
            accountFormat: '请输入正确的手机号',
            password: '请输入登录密码',
            verifyCode: '请输入验证码',
            success: '重置成功，请使用新密码登录',
            failed: '重置失败，请检查用户名、密码以及验证码',
            sendSuccess: '验证码发送成功，请注意查收',
            sendFailed: '验证码发送失败，请检查手机号是否正确并已注册',
        },
    },
    create: {
        title: '创建账户',
        pca: '省市区',
        companyName: '单位名称',
        companyUser: '负责人',
        companyAddress: '单位地址',
        submitBtn: '立即创建',
        verifyCodeBtn: '发送验证码',
        verifyCodeBtnSend: '$s 秒后重试',
        placeholder: {
            companyName: '请输入单位名称',
            companyUser: '请输入负责人姓名',
            companyUserPhone: '请输入负责人手机号',
            verifyCode: '请输入验证码',
            companyAddress: '单位所在地址，请点击地图选择单位所在地址',
            province: '请选择省份',
            city: '请选择城市',
            area: '请选择地区',
        },
        inputAlert: {
            province: '请选择省份',
            city: '请选择城市',
            area: '请选择地区',
            companyName: '请输入单位名称',
            companyUser: '请输入负责人姓名',
            companyUserPhone: '请输入手机号',
            companyUserPhoneFormat: '请输入正确的手机号',
            verifyCode: '请输入验证码',
            loadMapError: '地图加载异常，请确保您的网络能够正常访问高德地图',
            companyAddress: '请选择单位所在地址，请点击地图选择单位所在地址',
            success: '创建成功，默认密码为123456，请登录后及时修改密码',
            failed: '保存失败，请检查输入是否正确并确认手机号是否已注册',
            sendSuccess: '验证码发送成功，请注意查收',
            sendFailed: '验证码发送失败，请检查手机号是否正确并已注册',
        },
    }
}